const React = require("react");
const Layout = require("./src/components/Layout").default;

exports.wrapPageElement = ({ element, props }) => {
  return <Layout {...props}>{element}</Layout>;
};

exports.onRouteUpdate = ({ location, prevLocation }) => {
  const isIe =
    /MSIE (\d+\.\d+);/.test(navigator.userAgent) ||
    navigator.userAgent.indexOf("Trident/") > -1;

  if (prevLocation !== null && window.__ctwnd) {
    if (!isIe) {
      window.__ctwnd.reinit();
    }
  }
};
