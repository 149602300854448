import React from "react";
import { useStaticQuery, graphql } from "gatsby";

export const transformImageUrl = ({ siteUrl, imageUrl, width = null, height = null, fit = 'contain', quality = 75 }) => {
  let transformedImageUrl = ''

  if (typeof imageUrl === 'string') {
    transformedImageUrl = `${siteUrl}/.netlify/images?url=${encodeURIComponent(imageUrl)}&q=${quality}`;
    if (width) {
      transformedImageUrl += `&w=${width}`
    }
    if (height) {
      transformedImageUrl += `&h=${height}`
    }
    if (width && height) {
      transformedImageUrl += `&fit=${fit}`
    }
  }

  return transformedImageUrl
}

export default ({ image, alt, loading = 'lazy', width, height, fit = 'contain', quality = 75, decoding = 'async', fetchpriority = 'auto', className = '' }) => {
  let site = {
    siteMetadata: {
      siteUrl: 'https://safesitefacilities.co.uk'
    }
  }
  if (!process.env.STORYBOOK) {
    ({ site } = useStaticQuery(graphql`
      query SiteUrlQuery {
        site {
          siteMetadata {
            siteUrl
          }
        }
      }
    `))
  }

  return (
    <img src={transformImageUrl({ imageUrl: image, width, height, fit, quality, siteUrl: site.siteMetadata.siteUrl })} alt={alt} loading={loading} width={width} height={height} decoding={decoding} fetchpriority={fetchpriority} className={className} />
  )
}