import React, { useState, useContext, useEffect } from "react";
import { Link, useStaticQuery, graphql } from "gatsby";
import { SecondaryNavigation } from "./SecondaryNavigation";
import { ProductNavigation } from "./ProductNavigation";
import Container from "./Container";
import styled, { css } from "styled-components";
import SearchIcon from "./Icons/Search";
import MenuIcon from "./Icons/Menu";
import CloseIcon from "./Icons/Close";
import SearchInput from "./SearchInput";
import LogoIcon from "./Icons/Logo";
import LogoSmallIcon from "./Icons/LogoSmall";
import { globalHistory } from "@reach/router";
import { Helmet } from "react-helmet";
import CallTrackLink from "./CalltrackLink";
import ContactTelephoneWrapper from "./ContactTelephoneWrapper";
import { QuoteBasket } from "./QuoteBasket";
import BasketIcon from "./Basket";
import { QuoteBasketContext } from "../context/QuoteBasketContext";


const NavBarStyles = styled.div`
  ${({ ...props }) => css`
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 8px 0;
    height: 68px;

    @media (min-width: ${props.theme.breakpoints.lg}) {
      padding: 24px 0 8px 0;
      height: auto;
    }
  `}
`;

const LogoStyles = styled(Link)`
  ${({ ...props }) => css`
    @media (max-width: 991px) {
      display: none;
    }
  `}
`;

const LogoSmallStyles = styled(Link)`
  ${({ ...props }) => css`
    @media (min-width: ${props.theme.breakpoints.lg}) {
      display: none;
    }
  `}
`;

const IconButtonStyles = styled.button`
  ${({ ...props }) => css`
    background: none;
    padding: 0;
    line-height: 24px;
    color: ${props.theme.colors.darkGrey};

    svg {
      flex-shrink: 0;
      height: 24px;
    }

    span {
      display: block;
      color: ${props.theme.colors.darkGrey};
      line-height: 12px;
    }

    &:hover,
    &:focus {
      background: #fff;
      outline: none;
      color: ${props.theme.colors.darkGrey};
    }

    @media (min-width: ${props.theme.breakpoints.lg}) {
      display: none;
    }
  `}
`;

const NavigationStyles = styled.div`
  ${({ ...props }) => css`

    @media (max-width: 991px) {
      display: ${(props.$visible ? "block" : "none")};
      position: fixed;
      top: 68px;
      left: 0;
      width: 100vw;
      height: calc(100vh - 68px);
      overflow: auto;
      background: #fff;
      z-index: 9999;
      padding: 16px;
      border-top: solid 1px ${props.theme.colors.lightGrey};
    }

    @media (min-width: ${props.theme.breakpoints.md}) {
      .product-nav a {
        font-size: ${(props.$largeNavFont ? "14px" : "13px")};
      }
    }

    @media (min-width: ${props.theme.breakpoints.lg}) {
      display: flex;
      justify-content: space-between;
      align-items: center

      li {
        text-align: center;
        margin-right: 16px;
        border-bottom: solid 4px #fff;
      }

      a {
        text-transform: uppercase;
        font-weight: 700;
        text-align: center;
        padding: 16px 0;
        display: block;
        font-size: 13px;
        letter-spacing: 0.5px;
      }

      .product-nav a {
        font-size: ${(props.$largeNavFont ? "13px" : "13px")};
      }
    }

    @media (min-width: ${props.theme.breakpoints.xl}) {

    .product-nav a {
      font-size: ${(props.$largeNavFont ? "15px" : "13px")};
    }
    }
  `}
`;

const CTABarMobileStyles = styled.div`
  ${({ ...props }) => css`
    text-align: center;
    padding-bottom: 8px;
    border-top: solid 1px ${props.theme.colors.lightGrey};
    padding-top: 8px;
    strong {
      font-size: 18px;
    }
    @media (min-width: ${props.theme.breakpoints.lg}) {
      display: none;
    }
  `}
`;

const SearchInputContainerStyles = styled.div`
  ${({ ...props }) => css`
    @media (max-width: 991px) {
      display: ${(props.$visible ? "block" : "none")};
      position: fixed;
      top: 68px;
      left: 0;
      width: 100vw;
      height: calc(100vh - 68px);
      overflow: auto;
      background: #fff;
      z-index: 9999;
      padding: 16px;
      border-top: solid 1px ${props.theme.colors.lightGrey};
    }
    @media (min-width: ${props.theme.breakpoints.lg}) {
      display: flex;
    }
  `}
`;

const SecondaryNavigationStyles = styled(SecondaryNavigation)`
  ${({ ...props }) => css`
    @media (min-width: ${props.theme.breakpoints.lg}) {
      position: absolute;
      top: 4px;
      right: 48px;
    }
  `}
`;

const ProductNavigationStyles = styled(ProductNavigation)`
  ${({ ...props }) => css`
    @media (min-width: ${props.theme.breakpoints.lg}) {
      width: 100%;
    }
  `}
`;

const ContainerStyles = styled(Container)`
  ${({ ...props }) => css`
    @media (min-width: ${props.theme.breakpoints.lg}) {
      position: relative;
      padding-top: 24px;
    }
  `}
`;

const StyledMobileActionButtons = styled.div`
  ${({ ...props }) => css`
    display: flex;
    gap: 16px;
    align-items: center;
  `}
`;

export const Header = () => {
  const [navVisibleState, setNavVisibleState] = useState(false);
  const [searchVisibleState, setSearchVisibleState] = useState(false);
  const { setQuoteBasketIsActive } = useContext(QuoteBasketContext);

  const data = useStaticQuery(graphql`
    query HeaderQuery {
      wp {
        siteSettings {
          siteSettings {
            callToActionText
            telephone
            enableQuoteBasketSiteWide
          }
        }
      }
      site {
        siteMetadata {
          largeNavFont
        }
      }
    }
  `);

  const siteSettings = data.wp.siteSettings.siteSettings;
  const basketIsEnabled = siteSettings.enableQuoteBasketSiteWide;
  const largeNavFont = data.site.siteMetadata.largeNavFont;

  function handleCloseSearch() {
    setSearchVisibleState(false);
  }

  function handleCloseNav() {
    setNavVisibleState(false);
  }

  function handleOpenNav() {
    setNavVisibleState((navVisibleState) => !navVisibleState);
    setSearchVisibleState(false);
  }

  function handleOpenSearch() {
    setSearchVisibleState((searchVisibleState) => !searchVisibleState);
    setNavVisibleState(false);
  }

  useEffect(() => {
    return globalHistory.listen(({ action }) => {
      if (action === "PUSH") setNavVisibleState(false);
    });
  }, [setNavVisibleState]);

  // Quote basket

  const quoteBasketGloballyEnabled = siteSettings.enableQuoteBasketSiteWide;

  const handleOpenQuoteBasket = () => {
    setQuoteBasketIsActive(true);
  };

  return (
    <header>
      <Helmet>
        <style>
          {`
            body {
              overflow: ${navVisibleState || searchVisibleState ? "hidden" : "auto"}
            }
          `}
        </style>
      </Helmet>
      <ContainerStyles>
        <NavBarStyles>
          <LogoStyles to="/">
            <span className="sr-only">SafeSite Facilities</span>
            <LogoIcon />
          </LogoStyles>
          <LogoSmallStyles to="/">
            <span className="sr-only">SafeSite Facilities</span>
            <LogoSmallIcon />
          </LogoSmallStyles>

          <StyledMobileActionButtons>
            {basketIsEnabled && basketIsEnabled !== null && (
              <IconButtonStyles onClick={() => handleOpenQuoteBasket()}>
                <BasketIcon />
                <span>Quote</span>
              </IconButtonStyles>
            )}
            <IconButtonStyles onClick={handleOpenSearch}>
              {searchVisibleState ? <CloseIcon /> : <SearchIcon />}
              <span>Search</span>
            </IconButtonStyles>

            <IconButtonStyles onClick={handleOpenNav}>
              {navVisibleState ? <CloseIcon /> : <MenuIcon />}
              <span>Menu</span>
            </IconButtonStyles>
          </StyledMobileActionButtons>

          <SearchInputContainerStyles $visible={searchVisibleState}>
            <SearchInput
              title={siteSettings.callToActionText}
              telephone={siteSettings.telephone}
              searchAction={handleCloseSearch}
            />
          </SearchInputContainerStyles>
        </NavBarStyles>
        <CTABarMobileStyles>
          <p>Quick quotes. Nationwide coverage</p>
          <ContactTelephoneWrapper>
            <p>
              <strong>
                <CallTrackLink />
              </strong>
            </p>
          </ContactTelephoneWrapper>
        </CTABarMobileStyles>
        <NavigationStyles $visible={navVisibleState} $largeNavFont={largeNavFont}>
          <ProductNavigationStyles $navAction={handleCloseNav} />
          <SecondaryNavigationStyles $navAction={handleCloseNav} />
        </NavigationStyles>
      </ContainerStyles>

      {/* Quote basket */}
      {quoteBasketGloballyEnabled && <QuoteBasket />}
    </header>
  );
};
