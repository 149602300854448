import React from "react";
import { Link } from "gatsby";

export default ({ url, text }) => {
  if (url && text) {
    return (
      <>
        {
          url.includes('https://') || url.includes('http://') ? 
          <a 
            href={url}
            dangerouslySetInnerHTML={{
              __html: text,
            }}
          />
          :
          <Link
            to={url}
            dangerouslySetInnerHTML={{
              __html: text,
            }}
          />
        }
      </>
    )
  }
  return (<></>)
}
